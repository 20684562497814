import * as React from "react";
import FraudContainer from "../containers/fraud";
import DefaultLayout from "../layouts/default";
export default () => {

  return (
    <>
      <DefaultLayout animatedHeader title="Fraud">
        <FraudContainer />
      </DefaultLayout>
    </>
  );
};
